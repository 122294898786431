// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analisis-js": () => import("./../../../src/pages/analisis.js" /* webpackChunkName: "component---src-pages-analisis-js" */),
  "component---src-pages-audiovisual-js": () => import("./../../../src/pages/audiovisual.js" /* webpackChunkName: "component---src-pages-audiovisual-js" */),
  "component---src-pages-busqueda-js": () => import("./../../../src/pages/busqueda.js" /* webpackChunkName: "component---src-pages-busqueda-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cronicas-js": () => import("./../../../src/pages/cronicas.js" /* webpackChunkName: "component---src-pages-cronicas-js" */),
  "component---src-pages-ensayos-js": () => import("./../../../src/pages/ensayos.js" /* webpackChunkName: "component---src-pages-ensayos-js" */),
  "component---src-pages-entrevistas-js": () => import("./../../../src/pages/entrevistas.js" /* webpackChunkName: "component---src-pages-entrevistas-js" */),
  "component---src-pages-ficcion-js": () => import("./../../../src/pages/ficcion.js" /* webpackChunkName: "component---src-pages-ficcion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/Post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

